import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import ToolBox from "./toolBox";
import ToolDetailsSidebar from "../../toolsAndServices/components/toolDetailsSidebar";

function ToolMarketplaceDetails(props) {

  const [sidebarData, setSidebarData] = React.useState(false);

  return (
    <>
      <Box
        sx={{
          m: "0 var(--detailsBoxPadding)",
        }}
      >
        {props.list.filter(function (item) {
          if (props.selectedNodes.includes(item.id) || props.selectedNodes.toString().indexOf(item.id) !== -1) {
            return item
          }
        }).sort((a, b) => a.name.localeCompare(b.name)).map(function (parent) {
          return <Box key={parent.id}>
            <Typography sx={{fontSize: "1.1rem"}}>{parent.name}</Typography>
            <Box sx={{display: "flex", flexWrap: "wrap"}}>
              {parent.tools.filter(function(tool) {if (tool.visible) return tool}).map(function (tool, i) {
                return <ToolBox key={tool.id + i} tool={tool} tools={props.tools} setLoading={props.setLoading} setTools={props.setTools}/>
              })}
            </Box>
            {parent.children.filter(function (item) {
              if (props.selectedNodes.includes(item.id) || props.selectedNodes.toString().indexOf(item.id) !== -1) {
                return item
              }
            }).sort((a, b) => a.name.localeCompare(b.name)).map(function (child) {
              return <Box key={child.id}>
                <Typography variant={"h6"} sx={{mb: "16px", fontSize: "1rem"}}>{child.name}</Typography>
                <Box sx={{display: "flex", flexWrap: "wrap"}}>
                  {child.tools.filter(function(tool) {if (tool.visible) return tool}).map(function (tool) {
                    return <ToolBox key={tool.id} tool={tool} tools={!!props.tools ? props.tools : null} setLoading={props.setLoading} setTools={props.setTools}
                                    setSidebarData={setSidebarData} />
                  })}
                </Box>
              </Box>
            })}
          </Box>
        })
        }
      </Box>
      {!!sidebarData && <ToolDetailsSidebar closeSidebar={() => setSidebarData(false)} tool={sidebarData}
                                            isLoggedin={props.isLoggedin} setLoading={props.setLoading} tools={props.tools} setTools={props.setTools}/>}
    </>
  );
}

ToolMarketplaceDetails.propTypes = {
  employees: PropTypes.arrayOf(PropTypes.object),
  selectedUser: PropTypes.string,
  onClick: PropTypes.func,
  hideEmployees: PropTypes.arrayOf(PropTypes.string),
};

export default ToolMarketplaceDetails;