import React, { useState } from "react";
import { Box } from "@mui/system";
import {
  Avatar,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Dialog, DialogTitle, DialogActions, LinearProgress
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Check, Close, Logout, Person } from "@mui/icons-material";
import {
  API_deleteAccount,
  API_updateUserData,
  API_uploadPhoto,
} from "../services/backendService.jsx";
import { handleImageLoaded, isColorBright } from "../services/utils.jsx";
import Notification from "./notification.jsx";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { getCustomTheme } from "../customers/default.theme";

const Profile = (props) => {
  const { t, i18n } = useTranslation();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "info",
  });
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [firstname, setFirstname] = useState(
    props.data.first_name !== null ? props.data.first_name : ""
  );
  const [lastname, setLastname] = useState(
    props.data.last_name !== null ? props.data.last_name : ""
  );
  const [email, setEmail] = useState(
    props.data.email !== null ? props.data.email : ""
  );
  const [confirmDelete, setConfirmDelete] = useState("");
  React.useEffect(() => {
    setFirstname(
      props.data.first_name !== null ? props.data.first_name : ""
    );
    setLastname(
      props.data.last_name !== null ? props.data.last_name : ""
    );
    setEmail(
      props.data.email !== null ? props.data.email : ""
    );
  }, [props.data]);

  function uploadImage() {
    props.setLoading(true);
    API_uploadPhoto(
      event.target.files[0],
      "625233f3-7ad4-43c4-83d1-601514f0f121"
    ).then((r) => {
      updateData('{ avatar: {id: "' + r + '"}}', true);
    });
  }

  function updateData(object, changedImage) {
    props.setLoading(true);
    API_updateUserData(props.data.id, object).then((r) => {
      props.setUserData(r.data.update_users_item);
      setNotify({
        isOpen: true,
        message: t("toast_successfullySaved"),
        type: "success",
      });
      props.setLoading(false);
      if (changedImage) {
        document.getElementById("profile_avatar").src = "assets/loading.svg";
        document.getElementById("header_avatar").src = "assets/loading.svg";
      }
    });
  }

  function deleteAccount() {
    API_deleteAccount(props.data.id).then(() => {
      logout();
    });
  }

  const logout = () => {
    localStorage.clear();
    props.setShowProfile(false);
    setOpen(false);
    props.setLoggedin(null);
    navigate("/login");
  };

  let colors = [getCustomTheme().palette.primary.main, "#e91e63", "#9c27b0", "#673ab7",
                "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4",
                "#009688", "#4caf50", "#8bc34a", "#838853",
                "#ffeb3b", "#e6bc3f", "#7a6951", "#ff5722"
  ];

  return (
    <>
      <Notification notify={notify} setNotify={setNotify} />
      {!!props.data && (
        <Dialog
          fullScreen
          open={true}
          sx={{ width: "calc(var(--lineHeight) * 8)", left: "auto" }}
        >
          <Box sx={{ height: "100%", display: "flex", justifyContent: "center" }}>
            <Box>
              <Box sx={{backgroundColor: "primary.main", display: "flex", justifyContent: "space-between", alignItems: "center", p: "0 var(--listsPadding)"}}>
                <Typography variant={"h5"} sx={{ lineHeight: "var(--lineHeight)" }} color={"primary.contrastText"}>
                  {t("profile_header")}
                </Typography>
                <Close sx={{ color: "primary.contrastText" }} onClick={() => props.setShowProfile(false)} />
              </Box>
              <Box sx={{p: "1rem"}}>
                {/*Avatar*/}
                <Box sx={{ display: "flex", justifyContent: "start", pb: "1rem" }}>
                  <input
                    type="file"
                    hidden
                    onChange={uploadImage}
                    id={"uploadFileInput"}
                  />
                  <label htmlFor={"uploadFileInput"}>
                    <Avatar sx={{ cursor: "pointer", width: "227px", height: "227px" }}>
                      {!!props.data &&
                      !!props.data.avatar &&
                      !!props.data.avatar.id ? (
                        <Box
                          id={"profile_avatar"}
                          sx={{ width: "227px", height: "227px" }}
                          component="img"
                          alt=""
                          src={"assets/loading.svg"}
                          onLoad={(event) =>
                            handleImageLoaded(
                              event,
                              props.data.avatar.id,
                              "?height=227"
                            )
                          }
                        />
                      ) : (
                        <Person sx={{ width: "227px", height: "227px" }} />
                      )}
                    </Avatar>
                  </label>
                </Box>
                <Box sx={{ pb: "1rem" }}>
                  <TextField
                    sx={{
                      width: "var(--detailsBoxWidth)",
                      m: "5px 0 var(--detailsBoxPadding) 0",
                    }}
                    id="firstname"
                    label={t("employee_firstname")}
                    type={"text"}
                    value={firstname}
                    onChange={(event) => setFirstname(event.target.value)}
                    onBlur={(event) =>
                      updateData('{ first_name: "' + event.target.value + '" }')
                    }
                  />
                  <br/>
                  <TextField
                    sx={{
                      width: "var(--detailsBoxWidth)",
                      m: "5px 0 var(--detailsBoxPadding) 0",
                    }}
                    id="lastname"
                    label={t("employee_lastname")}
                    type={"text"}
                    value={lastname}
                    onChange={(event) => setLastname(event.target.value)}
                    onBlur={(event) =>
                      updateData('{ last_name: "' + event.target.value + '" }')
                    }
                  />
                  <br/>
                  <TextField
                    sx={{
                      width: "var(--detailsBoxWidth)",
                      m: "5px 0 var(--detailsBoxPadding) 0",
                    }}
                    id="lastname"
                    label={t("email")}
                    type={"text"}
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    onBlur={(event) =>
                      updateData('{ email: "' + event.target.value + '" }')
                    }
                  />
                  <br/>
                  {/*Sprache*/}
                  <FormControl>
                    <InputLabel id={"languageLabel"}>
                      {t("profile_language")}
                    </InputLabel>
                    <Select
                      label={t("profile_language")}
                      labelId={"languageLabel"}
                      value={
                        !!props.data && props.data.language ? props.data.language : ""
                      }
                      onChange={(event) => {
                        updateData('{ language: "' + event.target.value + '" }');
                        i18n.changeLanguage(event.target.value);
                      }}
                    >
                      <MenuItem value={"de-DE"}>
                        {t("profile_languageGerman")}
                      </MenuItem>
                      <MenuItem value={"en-US"}>
                        {t("profile_languageEnglish")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                {/*Colorpicker*/}
                <Box sx={{height: "5px"}}>{props.isLoading && <LinearProgress sx={{width: "200px"}} />}</Box>
                <Box sx={{display: "flex", flexWrap: "wrap", maxWidth: "200px"}}>
                  {colors.map((color) => {
                    return <Box key={color}
                                sx={{backgroundColor: color, width: "50px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer"}}
                                onClick={() => updateData('{ maincolor: "' + color + '" }')}
                    >
                      {props.data.maincolor === color && <Check sx={{color: isColorBright(color) ? "black" : "white"}}/>}
                    </Box>
                  })}
                </Box>
                <Box sx={{ pb: "1rem" }}>
                  <Box>
                    <Typography variant={"h5"} sx={{ pb: "1rem" }}>
                      {t("profile_deleteAccountHeader")}
                    </Typography>
                    <Typography variant={"body2"} sx={{ pb: "0.5rem" }}>
                      {t("deleteUser_description")}
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      sx={{ pb: "1rem" }}
                      label={t("profile_confirmDelete")}
                      variant={"outlined"}
                      value={confirmDelete}
                      type={"text"}
                      onChange={(e) => setConfirmDelete(e.target.value)}
                    ></TextField>
                  </Box>
                  <Button
                    variant={"contained"}
                    id={"deleteButton"}
                    disabled={
                      !((confirmDelete === "BESTÄTIGUNG" &&
                          i18n.language === "de-DE") ||
                        (confirmDelete === "CONFIRM" && i18n.language === "en-US"))
                    }
                    onClick={deleteAccount}
                  >
                    {t("profile_deleteAccountButton")}
                  </Button>
                </Box>
                <Box sx={{ mb: "1rem", display: "flex", alignItems: "center" }}>
                  <Logout sx={{pr: "1rem", cursor: "pointer"}} onClick={() => setOpen(true)} />
                  <Typography variant={"h6"} sx={{ cursor: "pointer" }} onClick={() => setOpen(true)} >{t('profile_logout')}</Typography>
                  <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {t("menu_requestLogout")}
                    </DialogTitle>
                    <DialogActions>
                      <Button onClick={() => setOpen(false)}>{t("no")}</Button>
                      <Button onClick={logout} autoFocus>
                        {t("yes")}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Box>
              </Box>
            </Box>
          </Box>
        </Dialog>
      )}
    </>
  );
};

Profile.propTypes = {
  setLoading: PropTypes.func,
  setUserData: PropTypes.func,
  data: PropTypes.shape({
    id: PropTypes.string,
    avatar: PropTypes.shape({
      id: PropTypes.string,
    }),
    couple_name: PropTypes.string,
    location: PropTypes.string,
    weddingDate: PropTypes.string,
    language: PropTypes.string,
  }),
  setLoggedin: PropTypes.func,
};

export default Profile;