import { alpha } from "@mui/material";
import { isColorBright } from "../services/utils";

export function getCustomTheme (mainColor) {
  if (!mainColor) {
    mainColor = "#244DBF";
  }
  return {
    typography: {
      fontFamily: "Roboto"
    },
    root: {
      fontFamily: "Roboto"
    },
    palette: {
      primary: {
        main: mainColor,
        contrastText: isColorBright(mainColor) ? "black" : "white"
      }
    },
    components: {
      MuiListItemButton: {
        styleOverrides: {
          root: {
            ':hover': {
              backgroundColor: alpha(mainColor, 0.10),
              color: "black",
              borderRadius: "6px"
            },
            borderRadius: "6px",
            paddingLeft: "14px",
            maxHeight: "50px"
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "6px",
            "&.Mui-disabled": {
              color: "rgba(0, 0, 0, 0.5)",
              borderColor: "rgba(0, 0, 0, 0.5)"
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            borderRadius: "6px",
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            borderRadius: "6px !important",
          }
        }
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            borderRadius: "6px",
          }
        }
      },
    }
  }
}